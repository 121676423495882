<template>
  <div class="post">
    <br>
    <ATitle text="公告"/>
    <div class="center">
      <!-- <DTitle text="新闻动态"/> -->
      <br>
      <router-link v-for="(item,index) in list" :key="index" tag="a" :to="'/news/'+item.id" class="post-item">
        <div class="fl cur-wrap">
          <div class="cur"></div>
        </div>
        <div class="top">
          <div class="title">{{item.title}}</div>
          <div class="desc">{{item.introduce}}</div>
        </div>
        <span class="time">{{$helper.formatTime(parseInt(item.pubDate), 'Y-M-D')}}</span>
      </router-link>
      <div style="height:32px;margin-bottom: 20px">
        <Page :total="page.total" :page-size="page.limit" @on-change="pageChange" style="float:right"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      page: {
        total: 0,
        limit: 20,
        page: 1,
        type: 2
      },
      aboutList: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    pageChange(e) {
      this.page.page = e
      this.getList()
    },
    getList() {
      this.$http.get('/meeting/news/page/wxff1c03d44d5b330b',{ // 普通新闻
        params: this.page
      }).then(res => {
        if (res.code === 0) {
          this.list = res.data.list
          this.page.total = res.data.total
        } else {
          this.$Message.warning(res.msg)
        }
      }).catch(err => {
        this.$Message.error(err)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.post {
  min-height: calc(100vh - 506px);
  .post-item {
    transition: all ease-in-out 0.3s;
    display: block;
    cursor: pointer;
    height: 140px;
    margin: 10px 0;
    padding: 0 10px;
    border-bottom: 1px solid #EEE;
    .cur-wrap {
      margin-top: 15px;
      position: relative;
      height: 60px;
      width: 2px;
      background: #EEE;
      .cur {
        position: absolute;
        height: 20px;
        width: 4px;
        top: 0;
        left: -1px;
        background: #0769CC;
      }
    }
    .top {
      height: 100px;
      padding: 10px;
      .title {
        height: 35px;
        font-size: 20px;
        color: #333;
        overflow: hidden;
        line-height: 30px;
      }
      .desc {
        height: 34px;
        line-height: 17px;
        font-size: 12px;
        color: #999;
        overflow: hidden;
      }
    }
    .time {
      margin-top: 20px;
      padding: 7px 15px;
      background: #F0EFF0;
      position: relative;
      font-size: 12px;
      color: #999;
      border-radius: 3px;
      margin-left: 12px;
    }
    .time::before {
      box-sizing: border-box;
      content: '';
      position: absolute;
      left: -8px;
      border-right: 10px solid #efedf0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid  transparent;
    }
  }
  .post-item:hover {
    box-shadow: 0 5px 5px #EEE;
    .title {
      color: #125FAD !important;
    }
  }
}
</style>